export const FaqArray = [
  {
    question: "I Cast Legend Lore on the mandolin.",
    answer: `BattleBards is the sense of discovery when journeying deep in an undiscovered land.  It’s the sizzle of a fireball flung by the party wizard holding back a horde of orcs.  It’s the welcoming voice of the local tavern keeper offering their services to a wary adventuring party. It’s immersive gaming. 
     <br/><br/> With the largest collection of professionally created, gaming specific audio, BattleBards is a tool for Game Masters to bring the epic feel to their epic stories.  More than just an incredible library of tracks, BattleBards provides the software needed to orchestrate games seamlessly.`,
  },
  {
    question: "I ask it what songs it knows.",
    answer:
      "BattleBards offers a growing catalog of thousands of tracks, ranging from music, soundscapes, sound effects, voice overs, and event-specific backdrops applicable across Fantasy, Sci-Fi, Cyberpunk, Modern, and Historic genres.",
  },
  {
    question: "Can you be used on both large and portable seeing stones?",
    answer:
      "BattleBards is available on all devices, including desktop and mobile.",
  },
  {
    question: "How much gold do your services cost?",
    answer: `You are given a free subscription as soon as you register, giving you access to our tools and several hundred tracks to stream and even some to download, again, for free.
    <br/><br/>
    If we earn a spot at your table and you want to unlock more, you can either:
    <br/><br/>
    Subscribe: Stream thousands of tracks as often as you like for less than you think.  Subscriptions start at $2.49/month and top out at $6.99/month
    <br/><br/>
    Purchase:  If you’re interested in specific tracks, you can purchase them, allowing you to download the MP3 and stream them on our platform.  Prices ranging from $0.20 to $1.75 per track
    `,
  },
  {
    question: "What’s the difference between subscription and track purchase?",
    answer: `A subscription gives you unlimited streaming access to one or more audio catalogs (Plate Mail Games, BattleBards, Both), accessible through our Soundboard.
    <br/><br/>
    Purchasing a track will grant you streaming access to that track as well as allow you to download the MP3.  You’ll have access to your files forever.`,
  },

  {
    question: "Other than songs, what abilities do you come with?",
    answer: `Soundboard: Your audio command and control, the Soundboard lets you place all tracks you’ll need for your next session at your fingertips as well as play tracks you didn’t plan on fast.  Customize your session’s audio experience through independent volume controls, concurrent audio layering, and component management.
    <br/><br/>
    Cast: Whether playing in person or remote, Cast allows you to play tracks to players joining your game from anywhere in the multiverse with one click.
    <br/><br/>
    Track Uploads:  Have your own audio?  Upload MP3 and integrate tracks into your playlists.
    `,
  },
  {
    question:
      "If I purchase tracks, where can I access the MP3 digital scroll?",
    answer: `You’ll find tracks you’ve purchased available for download in “My Library.”
    `,
  },
  {
    question:
      "How can I send everything you play to my party joining me many leagues away?",
    answer: `BattleBards Cast allows you to game with remote players, anywhere, on desktop or mobile.  Once in the Soundboard, you’ll see a red button on the top of the screen.  Click that to start your server and then click on the Link icon to copy your URL.  Paste that to anyone you want to join you and that’s that.
    <br></br>
    You’ll see everyone join your Cast session and anything you play on the Soundboard will transmit to them exactly the way you hear it on your end.
    <br></br>
    Your players do not have to be subscribed or even registered to join your Cast.`,
  },
  {
    question: "What if I really like having you in my party and what more?",
    answer: `If you want to upgrade your subscription, you can do so at any time by clicking`,
  },
  {
    question: "Can you be used in our own productions?",
    answer: `We love working with streamers and podcasters alike, in fact, we work with over 125 shows already and that list grows all the time.  As long as our audio is not being resold, repackaged, or used for any commercial purpose, you can request a copy of our agreement for review.  Our terms are pretty straightforward and it doesn’t take much to get started.
        <br></br>
        We’re planning on revamping our Podcaster Program in the future, bringing even more support and utility to those evangelizing this space we love so dearly.
        <br></br>
        For more details, please go to: <a href="https://battlebards.com/#/podcaster-license">https://battlebards.com/#/podcaster-license</a>`,
  },
  {
    question:
      "What if I too am a bard and want to add songs to your repertoire?",
    answer: `Think you have what it takes to produce the best tabletop gaming audio in existence? Email us at  <a
    href="mailto:submissions@battlebards.com">submissions@battlebards.com</a> and we’ll see if you have what it takes to get listed.`,
  },
  {
    question: "What if I lose the command word to activate you?",
    answer: `If you need to reset your account password, you can run through the recovery process by going to battlebards.com and clicking on “Reset Password”.`,
  },
];
