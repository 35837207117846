import React from "react";
import { creditBundleArray } from "./credit-bundle-array";
import Button from "../../../higher-order-components/button-hoc";
import Typography from "../../../higher-order-components/typography-hoc";

const CreditBundle = () => (
    <React.Fragment>
      <section className="pricing-section credit-bundles-div m-b-100">
        <div className="container">
          <Typography
            variant={"h2"}
            className={"font-weight-bold main-title m-t-15 text-center"}
          >
            Credit Bundles
          </Typography>

          <Typography variant={"body1"} className={"text-center m-b-50 m-t-20"}>
            Purchase the track to stream and download; buy credits to get even
            more for your buck.
          </Typography>

          <div className="row">
            {creditBundleArray.map((element, index) => (
              <div className="col-xs-12 col-sm-6 col-md-3" key={index}>
                <div className="pricing">
                  <div className="m-b-10">
                    <Typography
                      variant={"h4"}
                      className={
                        "fs-20 font-weight-medium text-left m-b-20 color-white"
                      }
                    >
                      {element.name}
                    </Typography>

                    <Typography variant={"h4"} className={"color-yellow fs-14"}>
                      {element.bonus}
                    </Typography>
                  </div>
                  <div className="m-b-20">
                    <Typography
                      variant={"h3"}
                      className={"color-yellow font-weight-bolder"}
                    >
                      {element.price}
                    </Typography>
                  </div>
                  <ul className="flex-1">
                    <li>
                      <div className="flex flex--justify-between">
                        <Typography variant={"body2"} className={""}>
                          {element.item1}
                        </Typography>
                        <Typography variant={"body2"} className={""}>
                          {element.item4}
                        </Typography>
                      </div>
                    </li>
                    <li>
                      <div className="flex flex--justify-between">
                        <Typography variant={"body2"} className={""}>
                          {element.item2}
                        </Typography>
                        <Typography variant={"body2"} className={""}>
                          {element.item3}
                        </Typography>
                      </div>
                    </li>
                  </ul>
                  <Button
                    className={
                      "btn btn-yellow btn-block border-radius-8 m-t-25"
                    }
                    icon={""}
                    position={""}
                    content={"Buy"}
                    onClick={null}
                  ></Button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </React.Fragment>
  );

export default CreditBundle;
