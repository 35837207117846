export const MigrationFaqArray = [
    {
        question: "BattleBards has leveled up, what’s new?",
        answer: `More than just a facelift, BattleBards brings new features to make gaming with our world class audio better and easier than ever before.  Enhanced architecture, improved library structure, a slimmer yet more powerful Soundboard, and the best remote player experience ever made through the new BattleBards Cast.`,
    },
    {
        question: "What happened to my BattleBards credits?",
        answer:
            "The credits will be transferred to the new website in the next 3 days.",
    },
    {
        question: "Why does my password not work?",
        answer:
            "Both sites have different passwords. Please sign up on this new site and we will sync your purchases and active subscription. You will be able to access the old site with your current account credentials.",
    },
    {
        question: "Where are the tracks I uploaded?",
        answer: `All of your uploaded and mixed tracks are there.  After signing into the new experience, it may take us a couple of hours to migrate your content.`,
    },
    {
        question: "I’m a BattleBards Artist, where do I access my dashboard?",
        answer: `We haven’t had the chance to port over the new artist experience.  For now, you can access your dashboard from the old site by <a href=https://legacy.battlebards.com>https://legacy.battlebards.com</a> to the legacy site.`,
    },

    {
        question: "I bought my subscription on the old site, how do I manage it now?",
        answer: `We’re still working on fully merging the backend of the old site to the new one.  For now, you can manage your subscription by <a href=https://legacy.battlebards.com>https://legacy.battlebards.com</a> to the legacy site.`,
    },
    {
        question:
            "Where’s the Mixer?",
        answer: `Of all our services, we haven’t had the chance to reimagine it quite yet.  For now, you can use the old Mixer by <a href=https://legacy.battlebards.com>https://legacy.battlebards.com</a> to the legacy site.
    `,
    },
];
