import React from "react";
import Discover from "../../component/landing-page/discover/discover";

const discover = () =>(
    <div>
      <Discover />
    </div>
  );

export default discover;
