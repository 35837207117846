import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import Router from "next/router";
import {StaticData} from "./staticData"
import Icon from "../../../common/icon/icon.js";
import Button from "../../../higher-order-components/button-hoc";
import Typography from "../../../higher-order-components/typography-hoc";
import { useHover } from "../../../common/function/hooks";
import {
  getLocal,
  removeLocal,
  setLocal,
} from "../../../common/function/storage";
import { handleHomePostInitialTrack, handlePostSQS } from "../../../redux-store/actions/albums";
import { checkLogOrNot, unixTimeStamp } from "../../../common/function/function";
import { getCurWaveTime } from "../../../common/function/waveServices";
const Home = () => {
  const dispatch = useDispatch();
  const store = useStore();
  const [hoverRef, isHovered] = useHover();

  const _CURRENT_PLAY_DATA = useSelector(
    (state) => state.common.currentPlayData
  );

  const [landingPageTitle, setLandingPageTitle] = useState();

  const _PLAYING_STATE = useSelector((state) => state.common.playingState);

  const HOME_PAGE_COUNT = useSelector((e) => e?.memorize?.count);
  let data = {
    "album_track_id": "277",
    "track_id": "277",
    "album_id": "47",
    "uri_name": "hear-ye-all-you-rascals",
    "duration": 191.896,
    "audio_type_id": "5",
    "owner_id": "1",
    "track_type": 1,
    "artist_id": "1",
    "title": "Hear ye All You Rascals",
    "list_price": 1.5,
    "net_price": 1.5,
    "discount": 0,
    "audio_type": "Music",
    "artist_name": "Jean-Baptiste",
    "cover_image_thumbnail": "https://bb20.s3.us-east-2.amazonaws.com/cover/halfling-campfire/w-100-h-100.jpg",
    "cover_image_small": "https://bb20.s3.us-east-2.amazonaws.com/cover/halfling-campfire/w-200-h-260.jpg",
    "cover_image_large": "https://bb20.s3.us-east-2.amazonaws.com/cover/halfling-campfire/w-250-h-325.jpg",
    "cover_image_1": "https://bb20.s3.us-east-2.amazonaws.com/cover/",
    "free_to_stream": false,
    "is_lock": false,
    "is_purchased": false
  };
  const _TOKEN = getLocal("access_token");

  useEffect(() => {
    if (window.performance && StaticData?.length) {
      if (performance.navigation.type === 1) {
        if (StaticData?.length <= HOME_PAGE_COUNT) {
          dispatch({ type: "HOME_PAGE_COUNT", payload: 0 });
          setLandingPageTitle(StaticData[0]);
        } else if (HOME_PAGE_COUNT === undefined) {
          setLandingPageTitle(StaticData[0]);
        } else {
          dispatch({ type: "HOME_PAGE_COUNT", payload: HOME_PAGE_COUNT + 1 });
          setLandingPageTitle(StaticData[HOME_PAGE_COUNT]);
        }
      } else {
        dispatch({ type: "HOME_PAGE_COUNT", payload: HOME_PAGE_COUNT + 1 });
        setLandingPageTitle(StaticData[0]);
      }
    }
  }, [StaticData?.length]);

  const handle = {
    playPauseTrack: async (data, i) => {
      const _WAVESURFER_OBJECT =
      store.getState()?.common?.waveSurferObject;
      let payload = {
        events:_PLAYING_STATE ? "play" : "pause",
        source: "store" ,
        track_id:  data?.track_id,
        start: getCurWaveTime(_WAVESURFER_OBJECT),
        end: getCurWaveTime(_WAVESURFER_OBJECT),
        is_purchased: data?.is_purchased,
        is_subscription: false,
        event_timestamp: unixTimeStamp()
      }
      if (
        _CURRENT_PLAY_DATA?.tracksData[_CURRENT_PLAY_DATA?.currentPlayingIndex]
          ?.track_id === data?.track_id
      ) {
        _WAVESURFER_OBJECT && _WAVESURFER_OBJECT?.playPause();
        dispatch(handlePostSQS(payload))
      } else {
        dispatch({
          type: "CURRENT_PLAY_DATA",
          payload: {
            isMultiplePlaying: false,
            currentPlayingIndex: 0,
            library: false,
            tracksData: [data],
          },
        });
      }
    },
    multiClick: (ele) => {
      if (
        _CURRENT_PLAY_DATA?.tracksData?.length === 0 &&
        _CURRENT_PLAY_DATA?.tracksData[_CURRENT_PLAY_DATA?.currentPlayingIndex]
          ?.track_id === "undefined"
      ) {
        handle.playPauseTrack(),
          dispatch({ type: "PLAYING_STATE", payload: false });
      } else {
        handle.playPauseTrack(ele),
          dispatch({
            type: "PLAYING_STATE",
            payload: !_PLAYING_STATE,
          });
      }
    },
    multiIcon: (data, isHovered) => {
      if (
        (_CURRENT_PLAY_DATA?.tracksData?.length === 0 &&
          _CURRENT_PLAY_DATA?.tracksData[
            _CURRENT_PLAY_DATA?.currentPlayingIndex
          ]?.track_id === "undefined") ||
        (_CURRENT_PLAY_DATA?.tracksData[_CURRENT_PLAY_DATA?.currentPlayingIndex]
          ?.track_id !== data?.track_id &&
          !isHovered)
      ) {
        return "icon-play-icon banner-play-push-icon m-r-10";
      } else if (
        _CURRENT_PLAY_DATA?.tracksData[_CURRENT_PLAY_DATA?.currentPlayingIndex]
          ?.track_id === data?.track_id &&
        !_PLAYING_STATE
      ) {
        return "icon-pause2 banner-play-push-icon m-r-10";
      } else {
        return "icon-play-icon banner-play-push-icon m-r-10";
      }
    },
  };

  useEffect(() => {
    removeLocal("/");
    setLocal("routers", "/");
  });

  useEffect(() => {
    if (_TOKEN) {
      checkLogOrNot(_TOKEN);
    }
  }, [_TOKEN]);
  return (
    <React.Fragment>
      <div id="home">
        <header className="masthead">
          <div className="container h-100">
            <div className="row h-100">
              <div className="col-sm-12 h-100">
                <div>
                  <div className="banner-block 11 mob-banner-block">
                    <div className="mob-homebanner-contains-div">
                      <Typography
                        variant={"body1"}
                        className={
                          "color-yellow font-weight-normal m-b-5 fs-18 capitalize-text"
                        }
                      >
                        {landingPageTitle?.audio_type}
                        {/* <Icon icon="info" size={16} color="orange" /> */}
                       {/*  Music */}
                      </Typography>
                      <Typography
                        variant={"h1"}
                        className={"color-white font-weight-medium m-b-25"}
                      >
                        {landingPageTitle?.title}
                     {/*    Bad Encounter */}
                      </Typography>
                     {/*  {landingPageTitle?.title ? ( */}
                        <div className="header mob-homebanner-btn-div">
                          <Button
                            className={
                              "btn btn-outline-white m-b-10 com-border-btn border-width-2 border-btn-hover more-info-btn "
                            }
                            icon={"icon-info-icon m-r-10"}
                            position={"left"}
                            content={"More Info"}
                            onClick={() => {
                              Router.push(
                                `/store/albums/${landingPageTitle?.album_uri_name}`
                              );
                              removeLocal("routers");
                              setLocal(
                                "routers",
                                `/store/albums/${landingPageTitle?.album_uri_name}`
                              );
                            }}
                          ></Button>
                          <Button
                            ref={hoverRef}
                            className={`${
                              _CURRENT_PLAY_DATA?.tracksData[
                                _CURRENT_PLAY_DATA?.currentPlayingIndex
                              ]?.track_id === landingPageTitle?.track_id &&
                              !_PLAYING_STATE
                                ? "btn-yellow active-yellow-btn"
                                : "btn-white"
                            } btn border-radius-8 m-b-10 border-width-2 common-btn-hover play-now-btn `}
                            icon={handle.multiIcon(landingPageTitle, isHovered)}
                            position={"left"}
                            content={"Play Now"}
                            onClick={() => {
                              handle.multiClick(landingPageTitle);
                            }}
                          ></Button>
                        </div>
                      {/* ) : null} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
    </React.Fragment>
  );
};

export default Home;
