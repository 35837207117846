export const StaticData = [
    
  {
      "album_track_id": "263",
      "album_id": "41",
      "track_id": "269",
      "album_uri_name": "dwarf-temple",
      "album_title": "Dwarf Temple",
      "cover_image_thumbnail": "https://bb20.s3.us-east-2.amazonaws.com/cover/dwarf-temple/w-100-h-100.jpg",
      "cover_image_small": "https://bb20.s3.us-east-2.amazonaws.com/cover/dwarf-temple/w-200-h-260.jpg",
      "cover_image_large": "https://bb20.s3.us-east-2.amazonaws.com/cover/dwarf-temple/w-250-h-325.jpg",
      "cover_image_1": "https://bb20.s3.us-east-2.amazonaws.com/cover/",
      "id": "269",
      "duration": 183.954,
      "audio_type_id": "5",
      "owner_id": "1",
      "track_type": 1,
      "artist_id": "1",
      "title": "Stone Father Hymn",
      "audio_type": "Music",
      "audio_type_color": "#bccd32",
      "artist_name": "Jean-Baptiste",
      "list_price": 1.5,
      "net_price": 1.5,
      "discount": 0,
      "uri_name": "stone-father-hymn",
      "lock_upload": false,
      "free_to_stream": false,
      "is_lock": true,
      "is_purchased": false
  },
  {
      "album_track_id": "271",
      "album_id": "47",
      "track_id": "277",
      "album_uri_name": "halfling-campfire",
      "album_title": "Halfling Campfire",
      "cover_image_thumbnail": "https://bb20.s3.us-east-2.amazonaws.com/cover/halfling-campfire/w-100-h-100.jpg",
      "cover_image_small": "https://bb20.s3.us-east-2.amazonaws.com/cover/halfling-campfire/w-200-h-260.jpg",
      "cover_image_large": "https://bb20.s3.us-east-2.amazonaws.com/cover/halfling-campfire/w-250-h-325.jpg",
      "cover_image_1": "https://bb20.s3.us-east-2.amazonaws.com/cover/",
      "id": "277",
      "duration": 191.896,
      "audio_type_id": "5",
      "owner_id": "1",
      "track_type": 1,
      "artist_id": "1",
      "title": "Hear ye All You Rascals",
      "audio_type": "Music",
      "audio_type_color": "#bccd32",
      "artist_name": "Jean-Baptiste",
      "list_price": 1.5,
      "net_price": 1.5,
      "discount": 0,
      "uri_name": "hear-ye-all-you-rascals",
      "lock_upload": false,
      "free_to_stream": false,
      "is_lock": true,
      "is_purchased": false
  },
  {
      "album_track_id": "377",
      "album_id": "59",
      "track_id": "387",
      "album_uri_name": "crazy-folk",
      "album_title": "Crazy Folk",
      "cover_image_thumbnail": "https://bb20.s3.us-east-2.amazonaws.com/cover/crazy-folk/w-100-h-100.jpg",
      "cover_image_small": "https://bb20.s3.us-east-2.amazonaws.com/cover/crazy-folk/w-200-h-260.jpg",
      "cover_image_large": "https://bb20.s3.us-east-2.amazonaws.com/cover/crazy-folk/w-250-h-325.jpg",
      "cover_image_1": "https://bb20.s3.us-east-2.amazonaws.com/cover/",
      "id": "387",
      "duration": 43.2685,
      "audio_type_id": "3",
      "owner_id": "1",
      "track_type": 1,
      "artist_id": "1",
      "title": "The Rat King Leads Court",
      "audio_type": "Voiceover",
      "audio_type_color": "red",
      "artist_name": "Jean-Baptiste",
      "list_price": 0.65,
      "net_price": 0.65,
      "discount": 0,
      "uri_name": "the-rat-king-leads-court",
      "lock_upload": false,
      "free_to_stream": false,
      "is_lock": true,
      "is_purchased": false
  },
  {
      "album_track_id": "3629",
      "album_id": "122",
      "track_id": "6231",
      "album_uri_name": "pirate-shanty",
      "album_title": "Pirate Shanty",
      "cover_image_thumbnail": "https://bb20.s3.us-east-2.amazonaws.com/cover/pirate-shanty/w-100-h-100.jpg",
      "cover_image_small": "https://bb20.s3.us-east-2.amazonaws.com/cover/pirate-shanty/w-200-h-260.jpg",
      "cover_image_large": "https://bb20.s3.us-east-2.amazonaws.com/cover/pirate-shanty/w-250-h-325.jpg",
      "cover_image_1": "https://bb20.s3.us-east-2.amazonaws.com/cover/",
      "id": "6231",
      "duration": 231.45,
      "audio_type_id": "5",
      "owner_id": "1",
      "track_type": 1,
      "artist_id": "1",
      "title": "Pirates of the Trackless Sea",
      "audio_type": "Music",
      "audio_type_color": "#bccd32",
      "artist_name": "Jean-Baptiste",
      "list_price": 1.5,
      "net_price": 1.5,
      "discount": 0,
      "uri_name": "pirates-of-the-trackless-sea",
      "lock_upload": false,
      "free_to_stream": false,
      "is_lock": true,
      "is_purchased": false
  },
  {
      "album_track_id": "4029",
      "album_id": "133",
      "track_id": "7080",
      "album_uri_name": "final-fleet-battle",
      "album_title": "Final Fleet Battle",
      "cover_image_thumbnail": "https://bb20.s3.us-east-2.amazonaws.com/cover/final-fleet-battle/w-100-h-100.jpg",
      "cover_image_small": "https://bb20.s3.us-east-2.amazonaws.com/cover/final-fleet-battle/w-200-h-260.jpg",
      "cover_image_large": "https://bb20.s3.us-east-2.amazonaws.com/cover/final-fleet-battle/w-250-h-325.jpg",
      "cover_image_1": "https://bb20.s3.us-east-2.amazonaws.com/cover/",
      "id": "7080",
      "duration": 194.495,
      "audio_type_id": "5",
      "owner_id": "1",
      "track_type": 1,
      "artist_id": "1",
      "title": "Desperate Defense of Earth",
      "audio_type": "Music",
      "audio_type_color": "#bccd32",
      "artist_name": "Jean-Baptiste",
      "list_price": 1.5,
      "net_price": 1.5,
      "discount": 0,
      "uri_name": "desperate-defense-of-earth",
      "lock_upload": false,
      "free_to_stream": false,
      "is_lock": true,
      "is_purchased": false
  },
  {
      "album_track_id": "4232",
      "album_id": "57",
      "track_id": "9618",
      "album_uri_name": "dungeon-exotic",
      "album_title": "Dungeon, Exotic",
      "cover_image_thumbnail": "https://bb20.s3.us-east-2.amazonaws.com/cover/dungeon-exotic/w-100-h-100.jpg",
      "cover_image_small": "https://bb20.s3.us-east-2.amazonaws.com/cover/dungeon-exotic/w-200-h-260.jpg",
      "cover_image_large": "https://bb20.s3.us-east-2.amazonaws.com/cover/dungeon-exotic/w-250-h-325.jpg",
      "cover_image_1": "https://bb20.s3.us-east-2.amazonaws.com/cover/",
      "id": "9618",
      "duration": 600.881,
      "audio_type_id": "2",
      "owner_id": "1",
      "track_type": 1,
      "artist_id": "1",
      "title": "Torch Lit Dungeon, Ghostly",
      "audio_type": "Soundscape",
      "audio_type_color": "#52CD71",
      "artist_name": "Jean-Baptiste",
      "list_price": 1.4,
      "net_price": 1.4,
      "discount": 0,
      "uri_name": "torch-lit-dungeon-ghostly",
      "lock_upload": false,
      "free_to_stream": false,
      "is_lock": true,
      "is_purchased": false
  },
  {
      "album_track_id": "4301",
      "album_id": "131",
      "track_id": "9860",
      "album_uri_name": "forest",
      "album_title": "Forest",
      "cover_image_thumbnail": "https://bb20.s3.us-east-2.amazonaws.com/cover/forest/w-100-h-100.jpg",
      "cover_image_small": "https://bb20.s3.us-east-2.amazonaws.com/cover/forest/w-200-h-260.jpg",
      "cover_image_large": "https://bb20.s3.us-east-2.amazonaws.com/cover/forest/w-250-h-325.jpg",
      "cover_image_1": "https://bb20.s3.us-east-2.amazonaws.com/cover/",
      "id": "9860",
      "duration": 600.048,
      "audio_type_id": "2",
      "owner_id": "1",
      "track_type": 2,
      "artist_id": "1",
      "title": "Winter Forest",
      "audio_type": "Soundscape",
      "audio_type_color": "#52CD71",
      "artist_name": "Jean-Baptiste",
      "list_price": 1.4,
      "net_price": 1.4,
      "discount": 0,
      "uri_name": "winter-forest",
      "lock_upload": false,
      "free_to_stream": false,
      "is_lock": true,
      "is_purchased": false
  },
  {
      "album_track_id": "4795",
      "album_id": "193",
      "track_id": "10354",
      "album_uri_name": "plane",
      "album_title": "Plane",
      "cover_image_thumbnail": "https://bb20.s3.us-east-2.amazonaws.com/cover/plane/w-100-h-100.jpg",
      "cover_image_small": "https://bb20.s3.us-east-2.amazonaws.com/cover/plane/w-200-h-260.jpg",
      "cover_image_large": "https://bb20.s3.us-east-2.amazonaws.com/cover/plane/w-250-h-325.jpg",
      "cover_image_1": "https://bb20.s3.us-east-2.amazonaws.com/cover/",
      "id": "10354",
      "duration": 600.059,
      "audio_type_id": "2",
      "owner_id": "1",
      "track_type": 2,
      "artist_id": "1",
      "title": "Labyrinth of the Dark Fey",
      "audio_type": "Soundscape",
      "audio_type_color": "#52CD71",
      "artist_name": "Jean-Baptiste",
      "list_price": 1.4,
      "net_price": 1.4,
      "discount": 0,
      "uri_name": "labyrinth-of-the-dark-fey",
      "lock_upload": false,
      "free_to_stream": false,
      "is_lock": true,
      "is_purchased": false
  },
  {
      "album_track_id": "4825",
      "album_id": "24",
      "track_id": "10385",
      "album_uri_name": "city-district",
      "album_title": "City, District",
      "cover_image_thumbnail": "https://bb20.s3.us-east-2.amazonaws.com/cover/city-district/w-100-h-100.jpg",
      "cover_image_small": "https://bb20.s3.us-east-2.amazonaws.com/cover/city-district/w-200-h-260.jpg",
      "cover_image_large": "https://bb20.s3.us-east-2.amazonaws.com/cover/city-district/w-250-h-325.jpg",
      "cover_image_1": "https://bb20.s3.us-east-2.amazonaws.com/cover/",
      "id": "10385",
      "duration": 600.059,
      "audio_type_id": "2",
      "owner_id": "1",
      "track_type": 2,
      "artist_id": "1",
      "title": "Daytime Medieval City",
      "audio_type": "Soundscape",
      "audio_type_color": "#52CD71",
      "artist_name": "Jean-Baptiste",
      "list_price": 1.4,
      "net_price": 1.4,
      "discount": 0,
      "uri_name": "daytime-medieval-city",
      "lock_upload": false,
      "free_to_stream": true,
      "is_lock": true,
      "is_purchased": false
  },
  {
      "album_track_id": "5375",
      "album_id": "157",
      "track_id": "10939",
      "album_uri_name": "battle",
      "album_title": "Battle",
      "cover_image_thumbnail": "https://bb20.s3.us-east-2.amazonaws.com/cover/battle/w-100-h-100.jpg",
      "cover_image_small": "https://bb20.s3.us-east-2.amazonaws.com/cover/battle/w-200-h-260.jpg",
      "cover_image_large": "https://bb20.s3.us-east-2.amazonaws.com/cover/battle/w-250-h-325.jpg",
      "cover_image_1": "https://bb20.s3.us-east-2.amazonaws.com/cover/",
      "id": "10939",
      "duration": 600.048,
      "audio_type_id": "2",
      "owner_id": "1",
      "track_type": 2,
      "artist_id": "1",
      "title": "Sci-Fi Shoot Out",
      "audio_type": "Soundscape",
      "audio_type_color": "#52CD71",
      "artist_name": "Jean-Baptiste",
      "list_price": 1.4,
      "net_price": 1.4,
      "discount": 0,
      "uri_name": "sci-fi-shoot-out",
      "lock_upload": false,
      "free_to_stream": false,
      "is_lock": true,
      "is_purchased": false
  },
  {
      "album_track_id": "5565",
      "album_id": "143",
      "track_id": "13162",
      "album_uri_name": "combat-skirmish",
      "album_title": "Combat, Skirmish",
      "cover_image_thumbnail": "https://bb20.s3.us-east-2.amazonaws.com/cover/combat-skirmish/w-100-h-100.jpg",
      "cover_image_small": "https://bb20.s3.us-east-2.amazonaws.com/cover/combat-skirmish/w-200-h-260.jpg",
      "cover_image_large": "https://bb20.s3.us-east-2.amazonaws.com/cover/combat-skirmish/w-250-h-325.jpg",
      "cover_image_1": "https://bb20.s3.us-east-2.amazonaws.com/cover/",
      "id": "13162",
      "duration": 178.714,
      "audio_type_id": "5",
      "owner_id": "1",
      "track_type": 1,
      "artist_id": "1",
      "title": "Duntrimir and Ellassar",
      "audio_type": "Music",
      "audio_type_color": "#bccd32",
      "artist_name": "Jean-Baptiste",
      "list_price": 1.5,
      "net_price": 1.5,
      "discount": 0,
      "uri_name": "duntrimir-and-ellassar",
      "lock_upload": false,
      "free_to_stream": false,
      "is_lock": true,
      "is_purchased": false
  },
  {
      "album_track_id": "5613",
      "album_id": "28",
      "track_id": "13302",
      "album_uri_name": "building-collapse",
      "album_title": "Building Collapse",
      "cover_image_thumbnail": "https://bb20.s3.us-east-2.amazonaws.com/cover/building-collapse/w-100-h-100.jpg",
      "cover_image_small": "https://bb20.s3.us-east-2.amazonaws.com/cover/building-collapse/w-200-h-260.jpg",
      "cover_image_large": "https://bb20.s3.us-east-2.amazonaws.com/cover/building-collapse/w-250-h-325.jpg",
      "cover_image_1": "https://bb20.s3.us-east-2.amazonaws.com/cover/",
      "id": "13302",
      "duration": 43.2803,
      "audio_type_id": "1",
      "owner_id": "1",
      "track_type": 1,
      "artist_id": "1",
      "title": "Tower Collapse",
      "audio_type": "Sound Effect",
      "audio_type_color": "#3498DB",
      "artist_name": "Jean-Baptiste",
      "list_price": 0.7,
      "net_price": 0.7,
      "discount": 0,
      "uri_name": "tower-collapse",
      "lock_upload": false,
      "free_to_stream": false,
      "is_lock": true,
      "is_purchased": false
  },
  {
      "album_track_id": "5705",
      "album_id": "131",
      "track_id": "13394",
      "album_uri_name": "forest",
      "album_title": "Forest",
      "cover_image_thumbnail": "https://bb20.s3.us-east-2.amazonaws.com/cover/forest/w-100-h-100.jpg",
      "cover_image_small": "https://bb20.s3.us-east-2.amazonaws.com/cover/forest/w-200-h-260.jpg",
      "cover_image_large": "https://bb20.s3.us-east-2.amazonaws.com/cover/forest/w-250-h-325.jpg",
      "cover_image_1": "https://bb20.s3.us-east-2.amazonaws.com/cover/",
      "id": "13394",
      "duration": 600,
      "audio_type_id": "2",
      "owner_id": "1",
      "track_type": 1,
      "artist_id": "1",
      "title": "Windy and Scary Woods",
      "audio_type": "Soundscape",
      "audio_type_color": "#52CD71",
      "artist_name": "Jean-Baptiste",
      "list_price": 1.4,
      "net_price": 1.4,
      "discount": 0,
      "uri_name": "windy-and-scary-woods",
      "lock_upload": false,
      "free_to_stream": false,
      "is_lock": true,
      "is_purchased": false
  },
  {
      "album_track_id": "6234",
      "album_id": "132",
      "track_id": "14513",
      "album_uri_name": "forest-exotic",
      "album_title": "Forest, Exotic",
      "cover_image_thumbnail": "https://bb20.s3.us-east-2.amazonaws.com/cover/forest-exotic/w-100-h-100.jpg",
      "cover_image_small": "https://bb20.s3.us-east-2.amazonaws.com/cover/forest-exotic/w-200-h-260.jpg",
      "cover_image_large": "https://bb20.s3.us-east-2.amazonaws.com/cover/forest-exotic/w-250-h-325.jpg",
      "cover_image_1": "https://bb20.s3.us-east-2.amazonaws.com/cover/",
      "id": "14513",
      "duration": 600.048,
      "audio_type_id": "2",
      "owner_id": "1",
      "track_type": 2,
      "artist_id": "1",
      "title": "Peaceful Fey Forest, Day",
      "audio_type": "Soundscape",
      "audio_type_color": "#52CD71",
      "artist_name": "Jean-Baptiste",
      "list_price": 1.4,
      "net_price": 1.4,
      "discount": 0,
      "uri_name": "peaceful-fey-forest-day",
      "lock_upload": false,
      "free_to_stream": false,
      "is_lock": true,
      "is_purchased": false
  }

]