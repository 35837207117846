import React from "react";
import Router from "next/router";
import { getLocal } from "../../../common/function/storage";
import Button from "../../../higher-order-components/button-hoc";
import Typography from "../../../higher-order-components/typography-hoc";
import useWindowDimensions from "../../../common/function/hooks/use-window-dimensions";

const Discover = () => {
  const _TOKEN = getLocal("access_token");
  const { width } = useWindowDimensions();
  let isMobile = width <= 767;
  return (
    <React.Fragment>
      {isMobile && !_TOKEN ? (
        <section className={"discover-section mob-pt-30"}>
          <div className="container">
            <div className="discover-block">
              <div className="mob-discover-contain-div">
                <div className="discover-box-main-div">
                <div className="discover-left-box">
                    <Typography
                      variant={"h1"}
                      className={"color-white font-weight-medium m-b-5"}
                    >
                      Discover
                    </Typography>

                    <Typography
                      variant={"body1"}
                      className={
                        "font-weight-normal m-b-35 fs-18 color-white opacity-0-7"
                      }
                    >
                      Register now and receive dozens of tracks free to download
                    </Typography>
                </div>
                <div className="discover-right-box">
                  <Button
                    className={
                      "btn btn-outline-white com-border-btn mob-sign-up-btn border-width-2 border-btn-hover button-transition-effect"
                    }
                    icon={""}
                    position={""}
                    content={"Sign Up"}
                    onClick={() => Router.push("auth/signUp")}
                  ></Button>
                </div>
                </div>
                

                
              </div>
            </div>
          </div>
        </section>
      ) : (
        !isMobile && (
          <section className={"discover-section mob-pt-30"}>
            <div className="container">
              {!_TOKEN && (
                <div className="discover-block">
                  <Typography
                    variant={"h1"}
                    className={"color-white font-weight-medium m-b-5"}
                  >
                    Discover
                  </Typography>

                  <Typography
                    variant={"body1"}
                    className={
                      "font-weight-normal m-b-35 fs-18 color-white opacity-0-7"
                    }
                  >
                    Register now and receive dozens of tracks free to download
                  </Typography>

                  <Button
                    className={
                      "btn btn-outline-white com-border-btn mob-sign-up-btn border-width-2 border-btn-hover button-transition-effect"
                    }
                    icon={""}
                    position={""}
                    content={"Sign Up"}
                    onClick={() => Router.push("auth/signUp")}
                  ></Button>
                </div>
              )}
            </div>
          </section>
        )
      )}
    </React.Fragment>
  );
};

export default Discover;
