export const creditBundleArray = [
    {
       name:"Elite Lute",
       bonus:"+$2.50 Bonus Credits",
       price:"Free",
       item1:"All Unlocks",
       item2:"Cloud Storage",
       item3:"500MB"
    },
    {
        name:"Mithral Lute",
        bonus:"+$7.00 Bonus Credits",
        price:"$100",
        item1:"Music",
        item2:"Soundscapes",
        item3:"800+",
        item4:"30+"
    },
    {
       name:"Adamantium Lute",
       bonus:"+$15.00 Bonus Credits",
       price:"$150",
       item1:"Music",
       item2:"Soundscapes",
       item3:"800+",
       item4:"30+"
    },
    {
       name:"BattleBard Lute",
       bonus:"+$36.00 Bonus Credits",
       price:"$300",
       item1:"Music",
       item2:"Soundscapes",
       item3:"800+",
       item4:"30+"

    }
]