import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Router from "next/router";
import Store from "./store";
import Discover from "./discover";
import PricingModel from "./pricing-model";
import Faq from "./faq";
import CreditBundle from "./credit-bundle";
import Home from "../../component/landing-page/home/home";
import Header from "../../component/landing-page/header/header";
import Footer from "../../component/landing-page/footer/footer";
import useWindowDimensions from "../../common/function/hooks/use-window-dimensions";
import MoreMenu from "../../component/button-navigation/more-menu";
const home = () => {
  const { width } = useWindowDimensions();
  let isMobile = width <= 767;
  const _OPEN_MORE_MENU = useSelector((state) => state?.common?.openMoreMenu);
  return (
    <React.Fragment>
      <div className="home_top_main_div mobfooter-mb-150">
        <Header />
        <Home />
        <Store />
        <Discover />
        <PricingModel />
        <Faq />
        {!isMobile && <Footer />}
        {_OPEN_MORE_MENU ? <MoreMenu /> : null}
      </div>
    </React.Fragment>
  );
};

export default home;
