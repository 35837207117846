import React, { useState, useRef } from "react";
import Router from "next/router";
import { FaqArray } from "./faq-array";
import { MigrationFaqArray } from "./migration-faq";
import Button from "../../../higher-order-components/button-hoc";
import { removeLocal, setLocal } from "../../../common/function/storage";
import Icon from "../../../common/icon/icon.js";

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState("");

  let NewRefs = [];

  const size = 4;
  const migrationsize = 7;

  return (

    <div >
      <section className="faq-section p-b-0 p-t-0 home-faq-section-div" id="relaunchfaq">
        <div className="container">
          <h2 className="font-weight-medium main-title text-center m-b-50">
            Relaunch FAQ
          </h2>
          <div className="accordion-wrapper" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1200">
            {MigrationFaqArray.slice(0, migrationsize).map((element, index) => {
              const isActive = index === activeIndex;

              const newRef = useRef(isActive);
              NewRefs.push(newRef);
              return (
                <React.Fragment key={index}>
                  <button
                    className={`${isActive ? "accordion" : "accordion"}`}
                    onClick={() =>
                      isActive ? setActiveIndex("") : setActiveIndex(index)
                    }
                  >
                    {element.question}

                    {/* <i
                      className={`${isActive
                          ? "icon-chevron-up active icomoon-icon-cls"
                          : "icon-chevron-down icomoon-icon-cls"
                        }`}
                      aria-hidden="true"
                    ></i> */}
                    {/* <Icon icon="icon-chevron-down" size={16} /> */}
                    {/* <span class="icon-twitter"></span> */}

                    <span
                      className={`${isActive
                          ? "icon-chevron-up icomoon-icon-cls active"
                          : "icon-chevron-down icomoon-icon-cls "
                        }`}
                      aria-hidden="true"
                    ></span> 
                  </button>

                  {isActive && (
                    <div className="panel">
                      <div className="panel-body">
                        <div
                          dangerouslySetInnerHTML={{ __html: element.answer }}
                        />
                      </div>
                    </div>
                  )}
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </section>
      
      <section className="faq-section p-b-0 p-t-0 home-faq-section-div" id="faq">
        <div className="container">
          <h2 className="font-weight-medium main-title text-center m-b-50">
            FAQ
          </h2>
          <div className="accordion-wrapper" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1200">
            {FaqArray.slice(0, size).map((element, index) => {
              const isActive = index === activeIndex;

              const newRef = useRef(isActive);
              NewRefs.push(newRef);
              return (
                <React.Fragment key={index}>
                  <button
                    className={`${isActive ? "accordion" : "accordion"}`}
                    onClick={() =>
                      isActive ? setActiveIndex("") : setActiveIndex(index)
                    }
                  >
                    {element.question}

                    {/* <i
                      className={`${
                        isActive
                          ? "icon-chevron-up active icomoon-icon-cls"
                          : "icon-chevron-down icomoon-icon-cls"
                      }`}
                      aria-hidden="true"
                    ></i> */}

                    <span
                      className={`${isActive
                          ? "icon-chevron-up icomoon-icon-cls active"
                          : "icon-chevron-down icomoon-icon-cls "
                        }`}
                      aria-hidden="true"
                    ></span> 
                  </button>

                  {isActive && (
                    <div className="panel">
                      <div className="panel-body">
                        <div
                          dangerouslySetInnerHTML={{ __html: element.answer }}
                        />
                      </div>
                    </div>
                  )}
                </React.Fragment>
              );
            })}
          </div>
          <div className="text-center">
            <Button
              className={
                "btn btn-outline-white m-t-20 com-border-btn m-b-200 border-width-2 border-btn-hover button-transition-effect"
              }
              icon={""}
              position={""}
              content={"See All"}
              onClick={() => {
                Router.push("/all-faq")
                removeLocal('routers');
                setLocal('routers', "/all-faq")
              }}
            ></Button>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Faq;
